<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="任务编码" v-bind="formItemLayout">
        <a-input v-model="form.taskCode" v-decorator="['taskCode']" disabled>
        </a-input>
      </a-form-model-item>
      <a-form-model-item
          label="任务状态"
          prop="status"
          v-bind="formItemLayout"
      >
        <!--        <a-input v-model="form.group" v-decorator="['group']" placeholder="标签分组">-->
        <!--        </a-input>-->
        <a-radio-group v-decorator="['status',{initialValue: 1}]" v-model="form.status">
          <a-radio :value="1">有效</a-radio>
          <a-radio :value="0">无效</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="任务名称" v-bind="formItemLayout">
        <a-input v-model="form.taskName" v-decorator="['taskName']" placeholder="任务名称，最多20个字" :max-length="20">
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="每日最多次数" v-bind="formItemLayout">
        <a-input-number v-model="form.times" v-decorator="['times']" placeholder="每日最多次数" :min="0">
        </a-input-number>
      </a-form-model-item>
      <a-form-model-item label="积分变化" v-bind="formItemLayout">
        <a-input-number v-model="form.score" v-decorator="['score']" placeholder="每日获取积分">
        </a-input-number>
      </a-form-model-item>
      <a-form-model-item label="描述说明" v-bind="formItemLayout">
        <a-textarea v-model="form.remark" v-decorator="['remark']" placeholder="描述说明" style="height:100px;">
        </a-textarea>
      </a-form-model-item>
      <a-form-model-item label="排序序号" v-bind="formItemLayout">
        <a-input-number v-model="form.sortNumber" v-decorator="['sortNumber']" placeholder="排序序号，越大越优先">
        </a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "ModifyConfigTaskModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {
        taskName: [
          {
            required: true,
            message: "任务名称不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    showModal(record, mode) {
      this.mode = mode || 'new'
      this.visible = true;
      if (this.mode === 'update') {
        this.title = "修改任务：" + record.taskName;
        this.form = {
          ...record,
        };
      } else {
        this.title = "新增任务"
        this.form = {}
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          this.$postJson("taskConfig/update", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.$emit("success");
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
